export const VGSFieldsSenderPTokenCodes = ['SenderCardNr'];
export const VGSFieldsSenderVTokenCodes = ['SenderCardCVV'];
export const VGSFieldsSenderValueCodes = ['SenderCardExpDate'];

export const VGSFieldsReceiverPTokenCodes = ['ReceiverCardNr'];

interface IVGSPostToken {
  key: string;
  value: string;
}
class VGSPostToken implements IVGSPostToken {
  key: string;
  value: string;
  constructor(data: any) {
    this.key = data.tokenBoxKey;
    this.value = data.formFieldCode;
  }
}

export interface IVGSPostIN {
  ptokens?: IVGSPostToken[]; // long-term tokenization
  vtokens?: IVGSPostToken[]; // short-term tokenization
  values?: IVGSPostToken[]; // disabled tokenization
}

export class VGSPostIN implements IVGSPostIN {
  ptokens?: IVGSPostToken[];
  vtokens?: IVGSPostToken[];
  values?: IVGSPostToken[];
  constructor(
    data: any,
    ptokencodes?: string[],
    vtokencodes?: string[],
    valuecodes?: string[]
  ) {
    if (!!ptokencodes) {
      this.ptokens = data
        .map((x) => new VGSPostToken(x))
        .filter((x) => ptokencodes.includes(x.value));
    }
    if (!!vtokencodes) {
      this.vtokens = data
        .map((x) => new VGSPostToken(x))
        .filter((x) => vtokencodes.includes(x.value));
    }
    if (!!valuecodes) {
      this.values = data
        .map((x) => new VGSPostToken(x))
        .filter((x) => valuecodes.includes(x.value));
    }
  }
  VGSRequestPrepare(formValues) {
    if (!!formValues['SenderCardNr'])
      this.ptokens.find((x) => x.value === 'SenderCardNr').value =
        formValues['SenderCardNr'];
    if (!!formValues['SenderCardCVV'])
      this.vtokens.find((x) => x.value === 'SenderCardCVV').value =
        formValues['SenderCardCVV'];
    if (!!formValues['SenderCardExpDate'])
      this.values.find((x) => x.value === 'SenderCardExpDate').value =
        formValues['SenderCardExpDate'];

    if (!!formValues['ReceiverCardNr'])
      this.ptokens.find((x) => x.value === 'ReceiverCardNr').value =
        formValues['ReceiverCardNr'];
  }
}

export interface IConfirmIN {
  transferPaymentKey: string;
  checkoutKey: string;
  p2psessionKey: string;
}

export interface IConfirmOUT {
  htmlForm3DS: string;
}

export interface ICheckBinCountryIN {
  cardBin: string;
}

export interface ICheckBinCountryOUT {
  countryCode: string;
}

export class CheckBinCountryOUT implements ICheckBinCountryOUT {
  countryCode: string;
  Checked(): boolean {
    return !!(this.countryCode === 'MD');
  }
}
