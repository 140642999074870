import { NgModule } from '@angular/core';
import { P2PComponent } from './p2p.component';
import { SenderBlockComponent } from './sender-block/sender-block.component';
import { ReceiverCardNrComponent } from './receiver-cardnr/receiver-cardnr.component';
import { P2PRoutingModule } from './p2p-routing.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CallbackPageTOComponent } from './callback-page-to/callback-page-to.component';

@NgModule({
  declarations: [
    P2PComponent,
    SenderBlockComponent,
    ReceiverCardNrComponent,
    CallbackPageTOComponent,
  ],
  imports: [
    CommonModule,
    P2PRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [],
  providers: [],
})
export class P2PModule {}
