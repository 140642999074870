import { Component } from "@angular/core";

@Component({
  selector: 'app-p2p',
  templateUrl: './p2p.component.html'
})

export class P2PComponent {

}
