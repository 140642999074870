import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SenderBlockComponent } from './sender-block/sender-block.component';
import { ReceiverCardNrComponent } from './receiver-cardnr/receiver-cardnr.component';
import { CallbackPageTOComponent } from './callback-page-to/callback-page-to.component';

const routes: Routes = [
  {
    path: 'sender-block/:lang',
    component: SenderBlockComponent,
  },
  {
    path: 'receiver-cardnr/:lang',
    component: ReceiverCardNrComponent,
  },

  {
    path: 'callback-page-to',
    component: CallbackPageTOComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class P2PRoutingModule {}
