import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export enum PM_ACTIONS {
  //PSP
  PSP_VGS_LOADED = 'PSP-VGS-LOADED',
  PSP_READY = 'PSP-READY',
  PSP_VALIDATE_IN = 'PSP-VALIDATE-IN',
  PSP_VALIDATE_OUT = 'PSP-VALIDATE-OUT',
  PSP_START = 'PSP-START',
  PSP_INIT_FIELDS = 'PSP-INIT-FIELDS',
  PSP_SUBMIT = 'PSP-SUBMIT',
  PSP_TOKENIZED = 'PSP-TOKENIZED',
  PSP_CONFIRM = 'PSP-CONFIRM',
  PSP_PREPARE_3DS = 'PSP-PREPARE-3DS',
  PSP_EXCEPTION = 'PSP-EXCEPTION',
  PSP_RENDER_STYLE = 'PSP-RENDER-STYLE',
  PSP_LINKEDCARDS_OBJ = 'PSP-LINKEDCARDS-OBJ',
  PSP_LINKEDCARDS_UPDATE_OBJ = 'PSP-LINKEDCARDS-UPDATE-OBJ',
  PSP_LINKEDCARDS_SET_CARDTOKEN = 'PSP-LINKEDCARDS-SET-CARDTOKEN',
  PSP_LINKEDCARDS_REMOVE_CARD_POPUP = 'PSP-LINKEDCARDS-REMOVE-CARD-POPUP',
}

export enum PM_FIELDS {
  PSP_SENDER_CARDNR = 'PSP-SENDER-CARDNR',
  PSP_SENDER_CARDCVV = 'PSP-SENDER-CARDCVV',
  PSP_SENDER_CARDEXP = 'PSP-SENDER-CARDEXP',
  PSP_SENDER_CARDBINCHECK = 'PSP-SENDER-CARDBINCHECK',
  PSP_RECEIVER_CARDNR = 'PSP-RECEIVER-CARDNR',
  PSP_RECEIVER_CARDBINCHECK = 'PSP-RECEIVER-CARDBINCHECK',
}

export const VGSFieldCSS = {
  '@font-face': {
    fontFamily: 'Circe-Regular',
    src: `url(${environment.p2pURL}/Circe-Regular.eot) format('embedded-opentype'), url(${environment.p2pURL}/Circe-Regular.otf)`,
  },
  color: '#000000',
  backgroundColor: '#f9fafc',
  'border-radius': '0.313rem',
  padding: '0.938rem 0.563rem',
  width: '25.9375rem',
  'box-sizing': 'border-box',
  'line-height': '1.813rem',
  'font-size': '1.563rem',
  '&::placeholder': {
    fontFamily: 'Circe-Regular',
    color: '#cacaca',
  },
  '&.invalid.touched': {
    color: '#cc4b37',
    border: '0.188rem solid #cc4b37',
  },
  '@media (max-width: 640px)': {
    color: '#000000',
    backgroundColor: '#f9fafc',
    'border-radius': '0.313rem',
    padding: '0.938rem 0.563rem',
    width: '100%',
    'box-sizing': 'border-box',
    'line-height': '1.438rem',
    'font-size': '1.25rem',
    '&.invalid.touched': {
      color: '#cc4b37',
      border: '0.188rem solid #cc4b37',
    },
  },
};
// export const css2 = {
//   ...css,
//   width: '8.438rem',
// };

// export const css3 = {
//   ...css2,
// };

export function waitForElementToAppear(element, _fn?) {
  return new Observable(function (observer) {
    var el_ref;
    let intervalId;

    var f = () => {
      el_ref = document.querySelector(element);
      // if (elementId === 'SenderCardNr') {
      //   console.log('==============================>>>>>>>>> SenderCardNr');
      // }
      if (el_ref) {
        clearInterval(intervalId);
        observer.next({ el_ref, _fn } as any);
        observer.complete();
        return;
      }

      //window.requestAnimationFrame(f);
      intervalId = setInterval(f, 100);
    };
    f();
  });
}
export function UploadScopedStyles(url) {
  // так как невозможно в компоненту засунуть global sass variable и привязаться к environment.ts
  // решено загружать внешние стили по рендеру в компоненте
  const styleTag = document.createElement('style');
  styleTag.innerText = `@import url('${url}');`;
  document.head.appendChild(styleTag);
}
