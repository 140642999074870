interface IVGSCollect {
  serviceType: string;
  vaultId: string;
  environment: string;
  version: string;
  url: string;
}
//https://tntkd2oedp0.sandbox.verygoodproxy.com/api/transferp2p/token

export const VGSCollect: IVGSCollect[] = [
  {
    serviceType: 'P2P',
    vaultId: 'tntkd2oedp0',
    environment: 'sandbox',
    version: '2.19.0',
    url: '/api/transferp2p/token',
  },
  //https://tntmtr6rgfk.sandbox.verygoodproxy.com/api/ecom/processlinkedcard
  {
    serviceType: 'SALE',
    vaultId: 'tntmtr6rgfk',
    environment: 'sandbox',
    version: '2.21.0',
    url: '/api/ecom/processlinkedcard',
  },
];

export const environment = {
  production: false,
  apiUrl: 'https://psp-api-test.paynet.md',
  p2pURL: 'https://p2p-test.paynet.md',
  oplatamdURL: 'https://test.oplata.md',
  paynetmdURL: 'https://test.paynet.md',
};
