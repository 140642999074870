import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgApiPipe } from './imgapi.pipe';
import { ImgSrcPipe } from './imgsrc.pipe';

@NgModule({
  declarations: [ImgApiPipe, ImgSrcPipe],
  imports: [CommonModule],
  exports: [ImgApiPipe, ImgSrcPipe],
})
export class PipesModule {}
