import { Component, OnInit, HostListener } from '@angular/core';
import { environment } from 'projects/psp-plugin/src/environments/environment';
import { loadVGSCollect } from '@vgs/collect-js';
import { VGSCollect } from 'projects/psp-plugin/src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import {
  PM_ACTIONS,
  PM_FIELDS,
  UploadScopedStyles,
  VGSFieldCSS,
  waitForElementToAppear,
} from '../../../_models/common.model';
import {
  CheckBinCountryOUT,
  ICheckBinCountryIN,
  VGSFieldsSenderPTokenCodes,
  VGSFieldsSenderVTokenCodes,
  VGSFieldsSenderValueCodes,
  VGSPostIN,
} from '../../../_models/psp.service.model';
import { FormField } from '../../../_models/p2p.fields.model';
import { PSPService } from '../../../_services/psp.service';
import { HttpErrorResponse } from '@angular/common/http';
import { CultureSharedService } from '../../../_services/culture.shared.service';

@Component({
  selector: 'app-sender-block',
  templateUrl: './sender-block.component.html',
})
export class SenderBlockComponent implements OnInit {
  private VGSCollect = VGSCollect.find((item) => item.serviceType === 'P2P');
  private form;
  private p2p = window.parent as Window;
  private fields_update: any;

  SenderCardNr: FormField;
  SenderCardCVV: FormField;
  SenderCardExpDate: FormField;

  CardNumberFormState;
  ExpDateFormState;
  CVVFormState;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private pspService: PSPService,
    private cultureSharedService: CultureSharedService
  ) {
    UploadScopedStyles(`${environment.p2pURL}/styles.PSP.css`);

    this.activatedRoute.params.subscribe((params) => {
      this.fields_update = {};
      if (!!params['lang']) {
        this.translateService.use(params['lang']);
        this.cultureSharedService.setLanguage(
          this.translateService.currentLang
        );
        // localStorage.setItem('currentLang', this.translateService.currentLang);
      }
    });
  }

  async ngOnInit() {
    // load script
    const collect: any = await loadVGSCollect({
      vaultId: this.VGSCollect.vaultId,
      environment: this.VGSCollect.environment,
      version: this.VGSCollect.version,
    }).catch((e) => {
      console.error('Failed to load VGSCollect:', e);
    });

    this.form = collect.init((state) => {
      //console.log('collect.init: ', state);
      // if (
      //   !!this.form.state['SenderCardNr']
      //   //&& this.form.state['card-security-code']
      // ) {
      //console.log('this.form', this.form);
      //console.log('this.form.state', this.form.state);
      //}
    });

    this.p2p.postMessage(
      {
        action: PM_ACTIONS.PSP_VGS_LOADED,
        iframe: 'PSPSenderBlock',
        fields: ['SenderCardNr', 'SenderCardCVV', 'SenderCardExpDate'],
      },
      environment.p2pURL
    );
  }

  private InitFields(VGSFields) {
    console.log('VGSFields: ', VGSFields);

    const currentfield1 = VGSFields.find((x) => x.id === 'SenderCardNr');
    let field1;
    this.translateService.get(currentfield1.placeholder).subscribe((text) => {
      this.SenderCardNr = new FormField(currentfield1, {
        VGSFieldCSS,
        showCardIcon: {
          left: '23rem',
          '@media(max-width: 640px)': {
            left: '86vw',
          },
        },
        placeholder: text,
      });
      waitForElementToAppear(`#${this.SenderCardNr.id}`).subscribe({
        next: () => {
          // console.log('======>  LOADED:', el_ref);

          field1 = this.form.field(
            `#${this.SenderCardNr.id}`,
            this.SenderCardNr.VGSObj
          );
          field1.on('update', (fieldstate) => {
            //console.log('SenderCardNr update: ', this.CardNumberFormState);

            this.CardNumberFormState = {
              bin: fieldstate.bin,
              hasbin: !!fieldstate.bin,
              haslast4: !!fieldstate.last4,
              checkedbin: null,
              isdirty: fieldstate.isDirty,
              isempty: fieldstate.isEmpty,
              isfocused: fieldstate.isFocused,
              istouched: fieldstate.isTouched,
              isinvalid: !fieldstate.isValid,
              isnotmastercard: fieldstate.cardType !== 'mastercard',
            };

            if (this.CardNumberFormState.hasbin) {
              // console.log('SenderCardNr: ', this.CardNumberFormState);
              this.CheckBIN(this.CardNumberFormState.bin);
            }

            this.p2p.postMessage(
              {
                action: PM_ACTIONS.PSP_VALIDATE_OUT,
                field: PM_FIELDS.PSP_SENDER_CARDNR,
                data: fieldstate,
              },
              environment.p2pURL
            );
          });
        },
      });
    });

    const currentfield2 = VGSFields.find((x) => x.id === 'SenderCardCVV');
    let field2;
    this.translateService.get(currentfield2.placeholder).subscribe((text) => {
      this.SenderCardCVV = new FormField(currentfield2, {
        VGSFieldCSS: {
          ...VGSFieldCSS,
          width: '6.65rem',
        },
        placeholder: text,
      });
      waitForElementToAppear(`#${this.SenderCardCVV.id}`).subscribe({
        next: () => {
          // console.log('======>  LOADED:', el_ref);
          field2 = this.form.field(
            `#${this.SenderCardCVV.id}`,
            this.SenderCardCVV.VGSObj
          );
          field2.on('update', (fieldstate) => {
            this.CVVFormState = {
              isdirty: fieldstate.isDirty,
              isempty: fieldstate.isEmpty,
              isfocused: fieldstate.isFocused,
              istouched: fieldstate.isTouched,
              isinvalid: !fieldstate.isValid,
            };
            this.p2p.postMessage(
              {
                action: PM_ACTIONS.PSP_VALIDATE_OUT,
                field: PM_FIELDS.PSP_SENDER_CARDCVV,
                data: this.form.state[this.SenderCardCVV.id],
              },
              environment.p2pURL
            );
          });
        },
      });
    });

    const currentfield3 = VGSFields.find((x) => x.id === 'SenderCardExpDate');
    let field3;
    this.translateService.get(currentfield3.placeholder).subscribe((text) => {
      this.SenderCardExpDate = new FormField(currentfield3, {
        VGSFieldCSS: {
          ...VGSFieldCSS,
          width: '6.65rem',
        },
        placeholder: text,
      });
      waitForElementToAppear(`#${this.SenderCardExpDate.id}`).subscribe({
        next: () => {
          field3 = this.form.field(
            `#${this.SenderCardExpDate.id}`,
            this.SenderCardExpDate.VGSObj
          );

          field3.on('update', (fieldstate) => {
            this.ExpDateFormState = {
              isdirty: fieldstate.isDirty,
              isempty: fieldstate.isEmpty,
              isfocused: fieldstate.isFocused,
              istouched: fieldstate.isTouched,
              isinvalid: !fieldstate.isValid,
            };
            this.p2p.postMessage(
              {
                action: PM_ACTIONS.PSP_VALIDATE_OUT,
                field: PM_FIELDS.PSP_SENDER_CARDEXP,
                data: this.form.state[this.SenderCardExpDate.id],
              },
              environment.p2pURL
            );
          });
        },
        complete: () => {
          Promise.all([field1?.promise, field2?.promise, field3?.promise]).then(
            () => {
              const p2p = window.parent as Window;
              p2p.postMessage(
                {
                  action: PM_ACTIONS.PSP_READY,
                  iframe: 'PSPSenderBlock',
                },
                environment.p2pURL
              );
            }
          );
        },
      });
    });
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    if (event.origin === environment.p2pURL && !!event.data) {
      switch (event.data.action) {
        case PM_ACTIONS.PSP_INIT_FIELDS:
          {
            console.log(event.data);

            this.InitFields(event.data.fields);
          }
          break;
        case PM_ACTIONS.PSP_VALIDATE_IN:
          {
            console.log(event.data);
            this.Validate();
          }
          break;
        case PM_ACTIONS.PSP_SUBMIT:
          {
            console.log(event.data);
            this.Submit(
              new VGSPostIN(
                event.data.tokenboxes,
                VGSFieldsSenderPTokenCodes,
                VGSFieldsSenderVTokenCodes,
                VGSFieldsSenderValueCodes
              )
            );
          }
          break;
        default:
          break;
      }
    }
  }

  private Validate() {
    if (
      this.CardNumberFormState.isinvalid ||
      this.CVVFormState.isinvalid ||
      this.ExpDateFormState.isinvalid
    ) {
      this.form.submit(
        '/',
        {
          data: () => {
            return {};
          },
        },
        (errors) => {
          console.log('Validate.validationCallback: ', errors);
        }
      );
    }
  }

  private Submit(IN: VGSPostIN) {
    if (IN) {
      this.form.submit(
        this.VGSCollect.url,
        {
          data: (formValues) => {
            IN.VGSRequestPrepare(formValues);
            return IN;
          },
        },
        (status, response) => {
          console.log('Submit.responseCallback: ', status, response);
          if (status === 200 && response.IsOK) {
            this.p2p.postMessage(
              {
                action: PM_ACTIONS.PSP_TOKENIZED,
                data: response,
              },
              environment.p2pURL
            );
          }
        },
        (errors) => {
          console.log('Submit.validationCallback: ', errors);
        }
      );
    }
  }

  private BinStateAfterCheck: string;
  private get BinStateCurrent(): string {
    return this.CardNumberFormState.bin;
  }
  private CheckedBinStateAfterCheck: boolean;

  private CheckBIN(cardBin: string) {
    if (this.BinStateAfterCheck != this.BinStateCurrent) {
      const IN: ICheckBinCountryIN = { cardBin };

      this.pspService.checkbincountry(IN).subscribe({
        next: (OUT: CheckBinCountryOUT) => {
          console.log(this.CardNumberFormState);
          this.CardNumberFormState.checkedbin = OUT.Checked();
          this.p2p.postMessage(
            {
              action: PM_ACTIONS.PSP_VALIDATE_OUT,
              field: PM_FIELDS.PSP_SENDER_CARDBINCHECK,
              checkedbin: this.CardNumberFormState.checkedbin,
            },
            environment.p2pURL
          );
          this.BinStateAfterCheck = this.BinStateCurrent;
          this.CheckedBinStateAfterCheck = this.CardNumberFormState.checkedbin;
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.CardNumberFormState.checkedbin = false;
          this.p2p.postMessage(
            {
              action: PM_ACTIONS.PSP_VALIDATE_OUT,
              field: PM_FIELDS.PSP_SENDER_CARDBINCHECK,
              checkedbin: this.CardNumberFormState.checkedbin,
            },
            environment.p2pURL
          );
        },
      });
    } else {
      this.CardNumberFormState.checkedbin = this.CheckedBinStateAfterCheck;
    }
  }
}
