import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, throwError } from 'rxjs';

import {
  CheckBinCountryOUT,
  ICheckBinCountryIN,
  ICheckBinCountryOUT,
  IConfirmIN,
  IConfirmOUT,
} from '../_models/psp.service.model';
import HttpClientUtils from '../_helpers/HttpUtils';

@Injectable({ providedIn: 'root' })
export class PSPService {
  constructor(private http: HttpClient) {}

  confirm(data: IConfirmIN) {
    return this.http.post(`api/psp/transfer/confirm`, data).pipe(
      map((response: IConfirmOUT) => {
        return response;
      }),
      catchError((error) => {
        console.log('Error (transfer/confirm): ', error.message);
        return throwError(() => error);
      })
    );
  }

  checkbincountry(IN: ICheckBinCountryIN) {
    const params = HttpClientUtils.toHttpParams(IN);
    return this.http
      .get(`api/psp/transfer/getcountrycodebycardbin`, {
        params,
      })
      .pipe(
        map((response: ICheckBinCountryOUT) => {
          const result = new CheckBinCountryOUT();
          result.countryCode = response.countryCode;
          return result;
        }),
        catchError((error) => {
          console.log(
            'Error (transfer/getcountrycodebycardbin): ',
            error.message
          );
          return throwError(() => error);
        })
      );
  }
}
