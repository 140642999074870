import { Component, HostListener } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'psp-plugin';
  langsArray = ['en', 'ro', 'ru'];
  constructor(public translateService: TranslateService) {
    translateService.addLangs(this.langsArray);

  }
}
