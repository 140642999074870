import { Component, HostListener, OnInit } from '@angular/core';
import { PSPService } from '../../../_services/psp.service';
import { environment } from 'projects/psp-plugin/src/environments/environment';
import { PM_ACTIONS } from '../../../_models/common.model';
import { IConfirmIN, IConfirmOUT } from '../../../_models/psp.service.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-callback-page-to',
  templateUrl: './callback-page-to.component.html',
})
export class CallbackPageTOComponent implements OnInit {
  private p2p = window.parent as Window;
  constructor(private pspService: PSPService) {}

  ngOnInit(): void {}

  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    if (event.origin === environment.p2pURL) {
      if (!!event.data && event.data.action === PM_ACTIONS.PSP_CONFIRM) {
        console.log(event.data);
        this.Confirm(event.data.object);
        //this.MOCK_Confirm(event.data.object);
      }
    }
  }

  private Confirm(data) {
    const IN: IConfirmIN = {
      checkoutKey: data.checkoutKey,
      transferPaymentKey: data.transferPaymentKey,
      p2psessionKey: data.p2psessionKey,
    };

    //console.log('Confirm.OUT', OUT);

    this.pspService.confirm(IN).subscribe({
      next: (OUT: IConfirmOUT) => {
        console.log(OUT);
        this.p2p.postMessage(
          {
            action: PM_ACTIONS.PSP_PREPARE_3DS,
            object: OUT,
          },
          environment.p2pURL
        );
      },
      error: (error: HttpErrorResponse) => {
        console.log(error);
        this.p2p.postMessage(
          {
            action: PM_ACTIONS.PSP_EXCEPTION,
            error: JSON.parse(JSON.stringify(error)),
          },
          environment.p2pURL
        );
      },
    });
  }

  // private MOCK_Confirm(IN: IConfirmIN) {
  //   const OUT_MOCK: IConfirmOUT = {
  //     //isAlreadyCompletedWithout3DS: false,
  //     // htmlForm3DS: `<HTML><HEAD></HEAD><BODY ONLOAD='javascript:OnLoadEvent();'><FORM ACTION='${environment.p2pURL}/callback-page-from/${IN.transferPaymentKey}/${IN.checkoutKey}/${IN.p2psessionKey}' METHOD='GET' NAME='ThreeDform' target='_self'><input name='creq' type='hidden' value='eyJ0aHJlZURTU2VydmVyVHJhbnNJRCI6IjI5MGZhY2QzLTEzZWEtNDdkNi1hZWY4LWY1MTg2ZDIyNDMxNCIsImFjc1RyYW5zSUQiOiIxM2Y5ZjkzNC02ZTgyLTQ3NGQtYTM3OS0xMTRiYjNmZTk4NWMiLCJjaGFsbGVuZ2VXaW5kb3dTaXplIjoiMDQiLCJtZXNzYWdlVHlwZSI6IkNSZXEiLCJtZXNzYWdlVmVyc2lvbiI6IjIuMS4wIn0'></FORM><SCRIPT>function OnLoadEvent (){  document.forms[0].submit();}</SCRIPT></BODY></HTML>`,
  //     htmlForm3DS: `<HTML><HEAD></HEAD><BODY ONLOAD='javascript:OnLoadEvent();'><FORM ACTION='https://oplatamd.localhost' METHOD='GET' NAME='ThreeDform' target='_self'><input name='creq' type='hidden' value='eyJ0aHJlZURTU2VydmVyVHJhbnNJRCI6IjI5MGZhY2QzLTEzZWEtNDdkNi1hZWY4LWY1MTg2ZDIyNDMxNCIsImFjc1RyYW5zSUQiOiIxM2Y5ZjkzNC02ZTgyLTQ3NGQtYTM3OS0xMTRiYjNmZTk4NWMiLCJjaGFsbGVuZ2VXaW5kb3dTaXplIjoiMDQiLCJtZXNzYWdlVHlwZSI6IkNSZXEiLCJtZXNzYWdlVmVyc2lvbiI6IjIuMS4wIn0'></FORM><SCRIPT>function OnLoadEvent (){  document.forms[0].submit();}</SCRIPT></BODY></HTML>`,
  //   };
  //   this.p2p.postMessage(
  //     {
  //       action: PM_ACTIONS.PSP_PREPARE_3DS,
  //       object: OUT_MOCK,
  //     },
  //     environment.p2pURL
  //   );
  // }
}
