import { CultureSharedService } from './../../../_services/culture.shared.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { environment } from 'projects/psp-plugin/src/environments/environment';
import { loadVGSCollect } from '@vgs/collect-js';
import { VGSCollect } from 'projects/psp-plugin/src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import {
  PM_ACTIONS,
  PM_FIELDS,
  UploadScopedStyles,
  VGSFieldCSS,
  waitForElementToAppear,
} from '../../../_models/common.model';
import { FormField } from '../../../_models/p2p.fields.model';
import {
  CheckBinCountryOUT,
  ICheckBinCountryIN,
  VGSFieldsReceiverPTokenCodes,
  VGSPostIN,
} from '../../../_models/psp.service.model';
import { PSPService } from '../../../_services/psp.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-receiver-cardnr',
  templateUrl: './receiver-cardnr.component.html',
})
export class ReceiverCardNrComponent implements OnInit {
  private VGSCollect = VGSCollect.find((item) => item.serviceType === 'P2P');
  private form;
  private p2p = window.parent as Window;
  private fields_update;

  ReceiverCardNr: FormField;

  CardNumberFormState;

  constructor(
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private pspService: PSPService,
    private cultureSharedService: CultureSharedService
  ) {
    UploadScopedStyles(`${environment.p2pURL}/styles.PSP.css`);
    this.activatedRoute.params.subscribe((params) => {
      this.fields_update = {};
      if (!!params['lang']) {
        this.translateService.use(params['lang']);
        this.cultureSharedService.setLanguage(
          this.translateService.currentLang
        );
        // localStorage.setItem('currentLang', this.translateService.currentLang);
      }
    });
  }

  async ngOnInit() {
    // load script
    const collect: any = await loadVGSCollect({
      vaultId: this.VGSCollect.vaultId,
      environment: this.VGSCollect.environment,
      version: this.VGSCollect.version,
    }).catch((e) => {
      //console.error('Failed to load VGSCollect:', e);
    });

    this.form = collect.init((state) => {
      // console.log('receiver card number vgs', state);
      if (!!this.form.state['ReceiverCardNr']) {
      }
    });
    this.p2p.postMessage(
      {
        action: PM_ACTIONS.PSP_VGS_LOADED,
        iframe: 'PSPReceiverBlock',
        fields: ['ReceiverCardNr'],
      },
      environment.p2pURL
    );
  }

  private InitFields(VGSFields) {
    console.log('VGSFields: ', VGSFields);

    let currentfield = VGSFields.find((x) => x.id === 'ReceiverCardNr');
    this.translateService.get(currentfield.placeholder).subscribe((text) => {
      this.ReceiverCardNr = new FormField(currentfield, {
        VGSFieldCSS,
        showCardIcon: {
          left: '23rem',
          '@media(max-width: 640px)': {
            left: '86vw',
          },
        },
        placeholder: text,
      });
      waitForElementToAppear(`#${this.ReceiverCardNr.id}`).subscribe({
        next: () => {
          //console.log('======>  LOADED:');

          const field1 = this.form.field(
            `#${this.ReceiverCardNr.id}`,
            this.ReceiverCardNr.VGSObj
          );

          field1.on('update', (fieldstate) => {
            //console.log('sender card-number fieldstate: ', fieldstate);

            this.CardNumberFormState = {
              bin: fieldstate.bin,
              hasbin: !!fieldstate.bin,
              haslast4: !!fieldstate.last4,
              checkedbin: null,
              isdirty: fieldstate.isDirty,
              isempty: fieldstate.isEmpty,
              isfocused: fieldstate.isFocused,
              istouched: fieldstate.isTouched,
              isinvalid: !fieldstate.isValid,
              isnotmastercard: fieldstate.cardType !== 'mastercard',
            };

            if (this.CardNumberFormState.hasbin) {
              //console.log('ReceiverCardNr: ', this.CardNumberFormState);
              this.CheckBIN(this.CardNumberFormState.bin);
            }

            this.p2p.postMessage(
              {
                action: PM_ACTIONS.PSP_VALIDATE_OUT,
                field: PM_FIELDS.PSP_RECEIVER_CARDNR,
                data: fieldstate,
              },
              environment.p2pURL
            );
          });
        },
        complete: () => {
          this.p2p.postMessage(
            {
              action: PM_ACTIONS.PSP_READY,
              iframe: 'PSPReceiverBlock',
            },
            environment.p2pURL
          );
        },
      });
    });
  }

  @HostListener('window:message', ['$event'])
  onPostMessage(event: MessageEvent) {
    if (event.origin === environment.p2pURL && !!event.data) {
      switch (event.data.action) {
        case PM_ACTIONS.PSP_INIT_FIELDS:
          {
            console.log(event.data);

            this.InitFields(event.data.fields);
          }
          break;
        case PM_ACTIONS.PSP_VALIDATE_IN:
          {
            console.log(event.data);
            this.Validate();
          }
          break;
        case PM_ACTIONS.PSP_SUBMIT:
          {
            console.log(event.data);
            this.Submit(
              new VGSPostIN(event.data.tokenboxes, VGSFieldsReceiverPTokenCodes)
            );
          }
          break;
        default:
          break;
      }
    }
  }

  private Validate() {
    if (this.CardNumberFormState.isinvalid) {
      this.form.submit(
        '/',
        {
          data: () => {
            return {};
          },
        },
        // (status, response) => {
        //   console.log('responseCallback: ', status, response);
        // },
        (errors) => {
          console.log('Validate.validationCallback: ', errors);
        }
      );
    }
  }

  private Submit(IN: VGSPostIN) {
    if (IN) {
      this.form.submit(
        this.VGSCollect.url,
        {
          data: (formValues) => {
            IN.VGSRequestPrepare(formValues);
            return IN;
          },
        },
        (status, response) => {
          console.log('Submit.responseCallback: ', status, response);
          if (status === 200 && response.IsOK) {
            this.p2p.postMessage(
              {
                action: PM_ACTIONS.PSP_TOKENIZED,
                data: response,
              },
              environment.p2pURL
            );
          }
        },
        (errors) => {
          console.log('Submit.validationCallback: ', errors);
        }
      );
    }
  }

  private BinStateAfterCheck: string;
  private get BinStateCurrent(): string {
    return this.CardNumberFormState.bin;
  }
  private CheckedBinStateAfterCheck: boolean;

  private CheckBIN(cardBin: string) {
    if (this.BinStateAfterCheck != this.BinStateCurrent) {
      const IN: ICheckBinCountryIN = { cardBin };
      this.pspService.checkbincountry(IN).subscribe({
        next: (OUT: CheckBinCountryOUT) => {
          console.log(this.CardNumberFormState);
          this.CardNumberFormState.checkedbin = OUT.Checked();
          this.p2p.postMessage(
            {
              action: PM_ACTIONS.PSP_VALIDATE_OUT,
              field: PM_FIELDS.PSP_RECEIVER_CARDBINCHECK,
              checkedbin: this.CardNumberFormState.checkedbin,
            },
            environment.p2pURL
          );
          this.BinStateAfterCheck = this.BinStateCurrent;
          this.CheckedBinStateAfterCheck = this.CardNumberFormState.checkedbin;
        },
        error: (error: HttpErrorResponse) => {
          console.log(error);
          this.CardNumberFormState.checkedbin = false;
          this.p2p.postMessage(
            {
              action: PM_ACTIONS.PSP_VALIDATE_OUT,
              field: PM_FIELDS.PSP_RECEIVER_CARDBINCHECK,
              checkedbin: this.CardNumberFormState.checkedbin,
            },
            environment.p2pURL
          );
        },
      });
    } else {
      this.CardNumberFormState.checkedbin = this.CheckedBinStateAfterCheck;
    }
  }
}
