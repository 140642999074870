export class FormField {
  id: string;
  label: string;
  VGSObj: VGSObj;
  constructor(data, UIObj) {
    this.id = `${data.id}`;
    this.label = data.label;
    this.VGSObj = new VGSObj(data, UIObj);
  }
}

export class VGSObj {
  name: string;
  type: string;
  placeholder: string;
  autoComplete: string;
  validations: string[];
  validCardBrands: any[];
  css?: any;
  showCardIcon?: any;
  constructor(data, UIObj) {
    this.name = data.name;
    this.type = data.type;
    this.autoComplete = data.autoComplete;
    this.validations = data.validations;
    this.validCardBrands = data.validCardBrands;
    this.placeholder = UIObj.placeholder;
    this.css = UIObj.VGSFieldCSS;
    this.showCardIcon = UIObj.showCardIcon;
  }
}
