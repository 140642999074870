import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CultureSharedService {
  currentLang$ = new Subject<string>()
  constructor() { }

  setLanguage(lang: string) {
    this.currentLang$.next(lang)
  }
}
